<template>
  <section class="login-page">
    <div class="row ma-0 pa-0">
      <div class="col-lg-6 pa-0">
        <div class="design-account-system">
          <div class="logo-section">
            <img :src="logo" alt="Company Logo" />
          </div>

          <div class="login-text">
            <h1>Designed for individuals</h1>
            <p>
              See the analytics and grow your data <br />
              remotely, from anywhere!
            </p>
          </div>

          <div class="login-image">
            <img src="account-design.png" alt="" />
          </div>

          <!-- <video class="login-video" width="820" height="440" autoplay muted>
            <source src="calculator.mp4" type="video/mp4" />
          </video> -->
        </div>
      </div>

      <div class="col-lg-6 pa-0">
        <div class="login-account-system">
          <div class="login-form">
            <h1>Login</h1>

            <form
              @submit.prevent="login"
              @keydown="form.errors.clear($event.target.name)"
            >
              <span v-if="form.errors.message" class="error-message"
                >{{ form.errors.message }} <br />
                <strong v-if="loginAttempts <= 3 && loginAttempts"
                  >You Have: {{ 3 - loginAttempts }} More Attempts Left</strong
                >
                <br />
              </span>
              <label for="fname">Email address:</label><br />
              <input
                type="text"
                id="fname"
                :class="form.errors.has('username') ? 'red' : ''"
                name="username"
                v-model="form.username"
                placeholder="example@gmail.com"
              /><br />
              <label for="lname">Password:</label><br />
              <input
                :type="unlock === true ? 'text' : 'password'"
                id="lname"
                :class="form.errors.has('password') ? 'red' : ''"
                name="password"
                v-model="form.password"
                placeholder="********"
              />
              <!-- <br /><br /> -->
              <input
                style="width: 4%"
                type="checkbox"
                value="lsRememberMe"
                id="rememberMe"
              />
              <label for="rememberMe">Remember Password</label>

              <input
                :disabled="
                  form.password.length < 8 ||
                  form.username.length < 3 ||
                  loginAttempts >= 3
                "
                style="background-color: #329faf"
                class="submit-button"
                type="submit"
                value="Login"
              />

              <h1 class="account-text">
                Don't have an account? <a href="#">Sign Up</a>
              </h1>

              <span class="seperation">
                <hr class="hr-left" />
                <p>or</p>
                <hr class="hr-right" />
              </span>

              <div class="google-form">
                <img src="google.png" alt="" />
                <h1>Authorize with Google</h1>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Form from '@/library/Form';
import cookie from 'js-cookie';
import { mapActions } from 'vuex';
import { getPropertyFromArrayObject } from '../../../library/helpers';
import ls from '../../../library/Storage';
import encryption from '@/library/Encryption';

export default {
  data: () => ({
    requestingPermission: false,
    roleSelectDialog: false,
    unlock: false,
    roleAvatar: [
      {
        name: 'administrator',
        avatar: 'https://image.flaticon.com/icons/svg/265/265675.svg',
      },

      {
        name: 'teacher',
        avatar: 'https://image.flaticon.com/icons/svg/1089/1089129.svg',
      },
      {
        name: 'accountant',
        avatar: 'https://image.flaticon.com/icons/svg/1728/1728858.svg',
      },
      {
        name: 'guardian',
        avatar: 'https://image.flaticon.com/icons/svg/374/374971.svg',
      },
    ],
    roles: [],
    items: [
      { header: 'Sign in as' },
      {
        avatar: 'https://image.flaticon.com/icons/svg/265/265675.svg',
        title: 'Administrator',
        subtitle:
          "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
      },
      { divider: true, inset: true },
      {
        avatar: 'https://image.flaticon.com/icons/svg/1089/1089129.svg',
        title:
          'Teacher <small class="grey--text text--lighten-1">12 Permissions</small>',
        subtitle:
          "<span class='text--primary'>Last Logged In</span> &mdash; 12 Hours Ago",
      },
      { divider: true, inset: true },
      {
        avatar: 'https://image.flaticon.com/icons/svg/1728/1728858.svg',
        title: 'Accountant',
        subtitle:
          "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
      },
    ],
    form: new Form(
      {
        username: '',
        password: '',
      },
      '/auth/login'
    ),
    loginAttempts: null,
  }),
  mounted() {},
  computed: {
    logo() {
      let companyLogo =
        cookie.get('_cl') ||
        'https://eacademy.sgp1.cdn.digitaloceanspaces.com/beta/school/rmUpdLgOX9vcimXTahH65yyJgJck8sOw4mpZIs3G.png';
      return location.host === 'lekhangkan.com' ||
        location.host === 'service.lekhangkan.com' ||
        location.host === 'lekhangkan.sahakaryatech.com'
        ? 'https://eacademy.sgp1.digitaloceanspaces.com/lekhangkan/logo_lekh.png'
        : companyLogo;
    },
  },
  methods: {
    ...mapActions(['setUser']),
    getProperty: getPropertyFromArrayObject,

    fetchCommunicatorToken() {
      this.$rest.get('/auth/communicator/token').then((res) => {
        if (res.data.communicate_token) {
          ls.set('_comm_tk', res.data.communicate_token);
        }
      });
    },

    login() {
      this.$rest
        .post(this.form.endpoint, this.form.data())
        .then(({ data }) => {
          this.$auth.set(data);
          this.setUser(this.$auth.user());
          this.fetchCommunicatorToken();
          this.$auth.setRole('accountant');
          this.$router.replace({ name: 'home' });
        })
        .catch((err) => {
          const { response } = err;
          if (response) {
            if (response.data.total_attempts !== 0) {
              this.loginAttempts = response.data.total_attempts;
            }
            this.form.errors.record(
              response.data.message,
              response.data.errors
            );
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/* New login page */
.login-page {
  .design-account-system {
    background-image: url('/login-design.png');
    padding: 40px;
    height: 100vh;
    background-color: #329faf;

    // background-color: #3853e0;
    .logo-section {
      height: 140px;
      width: 140px;
      img {
        width: 100%;
        color: #fff;
      }
    }

    .login-text {
      color: #fff;
      h1 {
        font-weight: 400;
        font-size: 28px;
      }
      p {
        color: #b0f0fa;
      }
    }

    .login-video {
      // position: absolute;
      bottom: 0;
      left: 0;
      right: 0px;
    }

    .login-image {
      // height: 600px;
      width: 600px;
      position: absolute;
      bottom: -7px;
      right: 837px;
      img {
        width: 100%;
      }
    }
  }

  .login-account-system {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 40px;
    height: 100vh;
    .login-form {
      width: 65%;
      h1 {
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 20px;
      }
      label {
        font-size: 14px;
        font-weight: 600;
        color: #606269;
      }
      input {
        width: 100%;
        border: 1px solid rgb(216, 214, 214);
        border-radius: 6px;
        padding: 8px;
        margin: 14px 0px;
        color: #999ca1;
      }

      .remember-part {
        height: 20px;
        width: 20px;
        background-color: #3853e0;
        p {
          color: #999ca1;
          font-size: 14px;
        }
      }

      .submit-button {
        background-color: #329faf !important;
        color: #fff;
      }

      .account-text {
        font-size: 16px;
        font-weight: 500;
        a {
          text-decoration: none;
        }
      }

      .seperation {
        color: #acadae;
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
        p {
          margin: 0px;
          padding: 0px 5px;
        }
        .hr-left {
          width: 265px;
          margin-left: 0;
          background-color: red;
        }

        .hr-right {
          width: 265px;
          margin-right: 0;
        }
      }

      .google-form {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgb(216, 214, 214);
        border-radius: 6px;
        padding: 10px;

        img {
          height: 20px;
          margin-right: 16px;
        }
        h1 {
          font-size: 18px;
          margin: 0px;
          font-weight: 500;
          color: #606269;
        }
      }
      .error-message {
        color: #ef4545;
        padding: 0px;
      }
    }
  }
}
</style>
